import '@insikt/rv-custom-video-player/dist/global.css';
import RVCustomVideoPlayer from '@insikt/rv-custom-video-player/dist/index';
import { getUserPremiumIntroRandomizer } from '@/src/shared/utils/user';
import { CustomVideoPlayerProps } from './types';
import { useMemo } from 'react';
import { deepCopy } from '@/src/shared/utils/deepCopy';
import { useRecordedVoice } from './useRecordedVoice';

export function CustomVideoPlayer({
  video,
  user,
  withoutRecordings,
  withRecordedVoice = false,
  options,
  ...rest
}: CustomVideoPlayerProps) {
  const randomizerBranding = getUserPremiumIntroRandomizer(user, video?.id);

  const videoObj = useMemo(() => {
    if (!video) return null;
    if (withoutRecordings) {
      const temp = deepCopy(video);
      if (temp.custom_intro != null) {
        delete temp.custom_intro;
      }
      if (temp.custom_video_on_video != null) {
        delete temp.custom_video_on_video;
      }
      return temp;
    }
    return video;
  }, [video, withoutRecordings]);

  const { voiceOverOptions } = useRecordedVoice(videoObj, {
    enabled: withRecordedVoice,
  });

  return (
    <RVCustomVideoPlayer
      key={`${JSON.stringify(user)}-${JSON.stringify(video)}-${JSON.stringify(
        voiceOverOptions
      )}`}
      {...rest}
      video={videoObj}
      user={randomizerBranding}
      options={
        {
          ...(options ?? {}),
          customAudio: options?.customAudio ?? voiceOverOptions,
        } as typeof options
      }
    />
  );
}
