import { useVideoVoiceSetting } from '@/src/data/agentDashboard/useVideoVoiceSetting';
import { useMemo } from 'react';
import type { IVideo } from '@/types/video';

interface Options {
  enabled?: boolean;
}

export function useRecordedVoice(video: IVideo, options?: Options) {
  const { data: videoVoiceSettings } = useVideoVoiceSetting(
    {
      videoId: video?.id,
    },
    {
      enabled: options?.enabled && video?.id != null,
    }
  );

  const voiceOverUrl = useMemo(() => {
    if (!options?.enabled) {
      return null;
    }
    return videoVoiceSettings?.custom_audios?.find((item) => {
      return item.type === videoVoiceSettings?.voice_over_type;
    })?.url;
  }, [
    options?.enabled,
    videoVoiceSettings?.custom_audios,
    videoVoiceSettings?.voice_over_type,
  ]);

  const voiceOverOptions = useMemo(() => {
    if (!options?.enabled) {
      return {
        hasVoiceOver: null,
        hasBackgroundMusic: null,
        url: null,
      };
    }
    return {
      hasVoiceOver: videoVoiceSettings?.has_voice_over,
      hasBackgroundMusic: videoVoiceSettings?.has_background_music,
      url: voiceOverUrl,
    };
  }, [
    options?.enabled,
    videoVoiceSettings?.has_background_music,
    videoVoiceSettings?.has_voice_over,
    voiceOverUrl,
  ]);

  return { voiceOverOptions };
}
